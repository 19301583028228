
import Vue from 'vue'
export default Vue.extend({
  name: "iconComponent",
  props: {
    name: {
      type: String,
      default: ""
    },
    size:{
      type: String,
      default: '24px'
    },
    boxSize:{
      type: String,
      default: '24px'
    }
  }
})
