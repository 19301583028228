
  import tabBarVue from '@/components/tabBar.vue'
  import catView from './game/catView.vue'
  import Vue from 'vue'
  import loginViewVue from './game/loginView.vue'

  export default Vue.extend({
    name: 'gameView',

    components: {
      tabBarVue,
      catView,
      loginViewVue
    },
    data: () => ({
      //
      tabBar: 0,
    }),
    computed:{
      logined(){
        return this.$store.state.game.sessionID !== '';
      }
    }
  })
