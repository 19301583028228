
import Vue from 'vue'
import icon from './icon.vue';
export default Vue.extend({
  name: "tabBar",
  components: {
    icon
  },
  props:{
    value:{
      type: Number,
      default: 0
    }
  },
  data: () => ({
    //
    tabItem:["happy", "bag", "shop"],
    selectedIndex: 0
  }),
  mounted(){
    this.selectedIndex = this.value
  },
  methods:{
    changeTab(index: number){
      this.selectedIndex = index
      this.$emit('input', index)
    }
  },
  watch:{
    value(val: number){
      this.selectedIndex = val
    }
  }
})
