import axios, { AxiosError } from 'axios';
import Vue from 'vue';

export function API<T>(url:string, data:any, toastWhileError = true){
  return new Promise((resolve:(value:T)=>void, reject) => {
    axios.post(
      // '/api/api/'+url, 
      'https://poop.nightship.cn/api/'+url, 
      data
    ).then((response) => {
      const data = response.data;
      if (data.status == 'success'){
        resolve(data.data as T);
      }
      else{
        reject(data.errMsg);
        if(toastWhileError) Vue.$toast.error(data.errMsg);
      }
    }).catch((error) => {
      reject(error.message);
      if(toastWhileError) Vue.$toast.error('网络错误:'+error.message);
    })
  })
}