
import iconVue from '@/components/icon.vue'
import Vue from 'vue'
import {API} from '@/plugins/API'
import md5 from 'js-md5'

interface ILoginResult{
  sessionID: string
  username: string
}

export default Vue.extend({
  name: "loginView",
  components:{
    iconVue,
  },
  data: () => ({
    mode: 0,
    // login
    login:{
      email: '',
      password: '',
    },
    loginLoading: false,
    registerLoading: false,
    remeber:false,
    register:{
      email: '',
      username: '',
      password: '',
      password2: '',
      catName: ''
    }
  }),
  methods:{
    loginSubmit(){
      this.loginLoading = true;
      API<ILoginResult>('login/login.php', 
        {
          email: this.login.email,
          password: md5(this.login.password)
        }
      ).then(res=>{
        this.$toast.success('登录成功');
        this.$store.commit('setLogin', res);

        if (this.remeber) {
          localStorage.setItem('sessionID', res.sessionID);
        }

      }).catch(err=>{
        console.log(err)
      }).finally(()=>{
        this.loginLoading = false;
      })
    },
    registerSubmit(){
      if (this.register.password !== this.register.password2) {
        this.$toast.warning('两次密码不一致')
        return
      }
      this.registerLoading = true;
      API('login/register.php', {
        email: this.register.email,
        username: this.register.username,
        password: md5(this.register.password),
        catName: this.register.catName
      }).then(res=>{
        this.$toast.success('注册成功，正在登录')
        this.login.email = this.register.email;
        this.login.password = this.register.password;
        this.loginSubmit();
      }).catch(err=>{
        console.log(err)
      }).finally(()=>{
        this.registerLoading = false;
      })
    },

    // 检查localStorage并自动登录
    autoLogin(){
      if(localStorage.getItem('sessionID')){
        this.loginLoading = true;
        API<ILoginResult>('login/autoLogin.php', {
          sessionID: localStorage.getItem('sessionID')
        }).then(res=>{
          this.$toast.success('自动登录成功');
          this.$store.commit('setLogin', res);
        }).catch(err=>{
          console.log(err)
        }).finally(()=>{
          this.loginLoading = false;
        })
      }
    }
  },
  mounted(){
    this.autoLogin();
  }
})
