
import verticalProgressLineVue from '@/components/verticalProgressLine.vue'
import Vue from 'vue'
import iconVue from '@/components/icon.vue';
import {API} from '@/plugins/API'
import moment from 'moment';

interface ICatBase{
  id: number,
  name: string,
  createTime: string,
  lastUpdateTime: string,
  nextPoopID: number,
  nextPoopTime: string,
}
interface ICartResult extends ICatBase{
  props: string|object,
  status: string|object,
}
interface ICat extends ICatBase{
  props:{
    healthDescRate: number;
    happyDescRate: number;
    healthDescFactor: number;
  },
  status:{
    hungry: number;
    health: number;
    happy: number;
  },
}

export default Vue.extend({
  name: "catView",
  components:{
    verticalProgressLineVue,
    iconVue,
  },
  data: () => ({
    //
    cat:{
      id: 0,
      name: 'cat',
      createTime: '',
      lastUpdateTime: '',
      nextPoopID: 0,
      nextPoopTime: '',
      props:{
        healthDescRate: 0,
        happyDescRate: 0,
        healthDescFactor: 0
      },
      status:{
        health:0,
        happy:0,
        hungry:0,
      }
    } as ICat,
    warningText: '猫饿了',
  }),
  methods:{
    getCatData(){
      API<ICartResult>("cat/getMyCat.php",{
        sessionID: this.$store.state.game.sessionID
      }).then(res=>{
        let cat:ICat = {
          ...res,
          props: JSON.parse(res.props as string),
          status: JSON.parse(res.status as string),
        };
        cat = this.updateCatStatus(cat);
        this.cat = cat;
      }).catch(err=>{
        console.log(err)
      })
    },
    // 计算猫当前的状态
    updateCatStatus(cat:ICat):ICat{
      const lastUpdateTime = moment.utc(cat.lastUpdateTime);
      const now = moment.utc();
      const dt = now.diff(lastUpdateTime, 'seconds');
      let health = cat.status.health;
      if(cat.status.hungry < 20){
        health += cat.props.healthDescRate * (dt/3600) * cat.props.healthDescFactor;
      }
      else{
        health += cat.props.healthDescRate * (dt/3600);
      }
      health = Math.max(0, health);

      let happy = cat.status.happy + cat.props.happyDescRate * (dt/3600);
      happy = Math.max(0, happy);

      const newCat = {
        ...cat,
        status:{
          ...cat.status,
          health,
          happy,
        }
      }

      return newCat;
    }
  },
  mounted(){
    this.getCatData();
  }
})
