
import Vue from 'vue'
export default Vue.extend({
  name: "verticalProgressLine",
  props:{
    value:{
      type: Number,
      default: 0
    },
    type:{
      type: String,
      default: 'full'
    }
  },
})
