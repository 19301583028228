import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    game: {
      sessionID: '',
      username: '',
    },
    admin:{
      sessionID: '',
      username: '',
    }
  },
  mutations: {
    setLogin(state, payload) {
      state.game.sessionID = payload.sessionID;
      state.game.username = payload.username;
    },
    setAdminLogin(state, payload) {
      state.admin.sessionID = payload.sessionID;
      state.admin.username = payload.username;
    }
  },
})
